
//window.mockConf=[];
 // mockConf.push("test/mock/outgame");
 ////mockConf.push("test/mock/loginInf");
 //mockConf.push("test/mock/myOrder");
 //mockConf.push("test/mock/rightnavdata");
 //mockConf.push("test/mock/gameInfo");
 //mockConf.push("test/mock/getMyUserInfo");
 // mockConf.push("test/mock/accountDetail");
 //mockConf.push("test/mock/dianquanDetail");
 //mockConf.push("test/mock/orderDetail");
 //mockConf.push("test/mock/myPublish");
 //mockConf.push("test/mock/msg");
 // mockConf.push("test/mock/goodslist");
 //mockConf.push("test/mock/gameAreaGroup");
 //mockConf.push("test/mock/firstPageInf");
 //mockConf.push("test/mock/msg");
 //mockConf.push("test/mock/onshelf");
 //mockConf.push("test/mock/upload");
 //mockConf.push("test/mock/feedback");
 //mockConf.push("test/mock/matchTrade");
 //mockConf.push("test/mock/promote");

